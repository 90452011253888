import React from "react";
import "./MemoriasSection.css";

const MemoriasSection = () => {
  const memorias = [
    {
      id: 1,
      nome: "Maria Oliveira",
      descricao: "“Virou trilha dos meus estudos”",
      imagem: "https://randomuser.me/api/portraits/women/65.jpg",
      texto:
        "Coloco o álbum deles pra tocar enquanto reviso partituras. O groove ajuda a concentrar e, quando vejo, já estudei horas sem cansar."
    },
    {
      id: 2,
      nome: "Rafael Martins",
      descricao: "“Workshop que mudou meu som”",
      imagem: "https://randomuser.me/api/portraits/men/78.jpg",
      texto:
        "Participei do workshop de improvisação deles em Floripa. Saí tocando escalas que nunca tinha entendido antes. Vale cada minuto!"
    },
    {
      id: 3,
      nome: "Helena Castro",
      descricao: "“Inspiração pras aulas”",
      imagem: "https://randomuser.me/api/portraits/women/56.jpg",
      texto:
        "Sou professora de música e uso as composições deles como exemplo de arranjo moderno brasileiro. Os alunos piram!"
    }
  ];

  return (
    <section className="memorias-section-show">
      <h1 className="memorias-title-show">DEPOIMENTOS</h1>
      <h2 className="memorias-subtitle-show">Memórias e Experiências Papayanas</h2>

      <div className="memorias-cards-show">
        {memorias.map((memoria) => (
          <div key={memoria.id} className="memoria-card-show">
            <div className="card-header-show">
              <img
                src={memoria.imagem}
                alt={memoria.nome}
                className="card-img-show"
              />
              <div className="card-info-show">
                <h3 className="card-name-show">{memoria.nome}</h3>
                <p className="card-desc-show">{memoria.descricao}</p>
              </div>
            </div>
            <p className="card-text-show">{memoria.texto}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MemoriasSection;
