import React from "react";
import "./ProximosShowsSection.css";

const ProximosShowsSection = () => {
  return (
    <div className="proximos-shows">
      <div className="content-show">
        <div className="left-section-show">
          <p className="next-show">PRÓXIMO SHOW | 19 DE NOV DE 2024 - 20H</p>
          <h1>Arena Multiuso Estener <br /> Soratto da Silva</h1>
          <p className="address-show">Rua Manaus, 172 - Vila Moema, Tubarão</p>
          <button className="ticket-button-show">Ingressos</button>
        </div>

        <div className="separator-show">
          <hr />
        </div>

        <div className="right-section-show">
          <img src="qrCodeImg.png" alt="QR Code" className="qr-code" />
        </div>
      </div>

      <div className="shows-section-show">
        <div className="shows-container-show">
          <div className="shows-content-show">
            <div className="show-item-show">
              <div className="data-and-local">
                <img src="rectangle.svg" alt="Imagem SVG" className="show-image-show" />
                <div className="show-text-show">
                  <div className="show-data">21 NOV</div>
                  <div className="show-place">Joinville-SC</div>
                </div>
              </div>
            </div>

            <div className="show-item-show">
              <div className="show-address-show">
                <img className="icon" src="location_on.svg" alt=""></img>
                <p className="local-show">Teatro da LIGA, Rua Jaguaruna, 100, <br /> Centro de Joinville</p>
              </div>
            </div>

            <div className="show-item-show">
              <div className="show-hour-container">
                <img className="icon" src="hour.svg" alt="Ícone de horário" />
                <div className="show-hour">às 20h</div>
              </div>
            </div>

            <div className="show-item-show">
              <button className="buy-ticket-btn">Ingressos</button>
            </div>
          </div>

          {/* Segunda Linha */}
          <div className="shows-content-show">
            <div className="show-item-show">
              <div className="data-and-local">
                <img src="rectangle.svg" alt="Imagem SVG" className="show-image-show" />
                <div className="show-text-show">
                  <div className="show-data">28 NOV</div>
                  <div className="show-place">Florianópolis - SC</div>
                </div>
              </div>
            </div>

            <div className="show-item-show">
              <div className="show-address-show">
                <img className="icon" src="location_on.svg" alt=""></img>
                <p className="local-show">Teatro Ademir Rosa CIC, Avenida Gov. <br />Irineu Bornhausen, 5600, Agronômica, < br /> Florianópolis</p>
              </div>
            </div>

            <div className="show-item-show">
              <div className="show-hour-container">
                <img className="icon" src="hour.svg" alt="Ícone de horário" />
                <div className="show-hour">às 20h</div>
              </div>
            </div>

            <div className="show-item-show">
              <button className="buy-ticket-btn">Ingressos</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default ProximosShowsSection;




