import React from 'react';
import './NotFound.css';

export default function NotFound() {
  return (
    <main className="notfound">
      <div className="notfound-overlay" aria-hidden="true" />

      <div className="notfound-content">
        <h1 className="notfound-code">404</h1>
        <p className="notfound-message">Opa! Essa página não existe.</p>
        <a href="/" className="notfound-btn">Voltar para a Home</a>
      </div>
    </main>
  );
}
