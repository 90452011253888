import React, { useState } from 'react';
import './Galeria.css';

const Galeria = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [hover, setHover] = useState(false);

    const images = [
        { src: 'galeria-1.png', alt: 'Imagem 1' },
        { src: 'galeria-2.png', alt: 'Imagem 2' },
        { src: 'galeria-3.png', alt: 'Imagem 3' },
    ];

    const thumbnails = [
        { src: 'thumb1.png', alt: 'Thumbnail 1', title: 'Comemoração 25 anos', description: 'Papaya 2019' },
        { src: 'thumb2.png', alt: 'Thumbnail 2', title: 'Ensaio com Steve Vai', description: 'Brasil Papaya 2015 - ROCK IN RIO' },
    ];

    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
    };

    const nextSlide = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const prevSlide = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const goToSlide = (index) => {
        setCurrentImageIndex(index);
    };

    return (
        <section className="galeria">
            <div className="carousel-container-galeria">
                <div className="carousel-galeria">
                    <div
                        className="carousel-image-galeria"
                        style={{ backgroundImage: `url(${images[currentImageIndex].src})` }}
                    />
                </div>
                <div className="carousel-controls-galeria">
                    <button className="carousel-button-galeria prev" onClick={prevSlide}>
                        &#10094;
                    </button>
                    <button className="carousel-button-galeria next" onClick={nextSlide}>
                        &#10095;
                    </button>
                </div>

                <div className="carousel-indicators-galeria">
                    {images.map((_, index) => (
                        <span
                            key={index}
                            className={`indicator-galeria ${index === currentImageIndex ? 'active' : ''}`}
                            onClick={() => goToSlide(index)}
                        ></span>
                    ))}
                </div>
            </div>

            <div className="thumbnails-galeria">
                <div className="thumbnail-title">
                    <h3>Galeria</h3>
                    <button
                        className={`button-see-more-galeria ${hover ? "hovered" : ""}`}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <span className={`button-text ${hover ? "hidden" : ""}`}>Ver tudo</span>
                        <img
                            src="arrow-right.png"
                            alt="Seta"
                            className={`button-icon ${hover ? "hidden" : ""}`}
                        />
                    </button>
                </div>
                <div className="thumbnail-destaque">
                    <img src="thumb.png" alt="Brasil Papaya" />
                    <div className="thumbnail-destaque-title">
                        <h4>Comemoração 25 anos</h4>
                        <p>Papaya 2019</p>
                    </div>
                </div>
                <div className="thumbnail-list-galeria">
                    {thumbnails.map((thumbnail, index) => (
                        <div
                            key={index}
                            className={`thumbnail-galeria ${index === currentImageIndex ? 'active' : ''}`}
                            onClick={() => handleThumbnailClick(index)}
                        >
                            <img src={thumbnail.src} alt={thumbnail.alt} />
                            <div className="thumbnail-info-galeria">
                                <h4>{thumbnail.title}</h4>
                                <p>{thumbnail.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Galeria;
