import React from 'react';
import './BrasilPapayaSection.css';

const BrasilPapayaSection = () => {
    return (
        <section className="brasil-papaya">
            <div className="banda">
            <div className="container-banda">
                <p className="year">DESDE 1993</p>
                <h1 className="title">
                    <span>Brasil</span>
                    <span>Papaya</span>
                </h1>
                <div className="image-container">
                    <img src="banda.jpg" alt="Brasil Papaya" className="background-image" />
                </div>
            </div>
            <p className="quote">
                <em>“A banda lançou seu primeiro álbum homônimo em 1997, seguido de “Esperanza” em 2006, ambos aclamados pela crítica especializada.”</em>
            </p>
            <div className="content-banda">
                <div className="left-column">
                    <p className="details">
                        Brasil Papaya nasceu com a missão de derrubar o estigma de que música instrumental é elitista e inacessível. Desde as primeiras gravações em Laguna, Santa Catarina, e da estreia ao vivo em 1994.
                    </p>
                    <p className="details">
                        O Brasil Papaya vem rompendo barreiras, misturando heavy metal com gêneros como choro, blues e flamenco, sempre mantendo uma pegada autêntica de rock and roll.
                    </p>
                    <p className="details">
                        Esse estilo inovador chamou a atenção de músicos e marcas nacionais, levando a banda para além do underground e consolidando seu lugar na cena musical. Ao longo dos anos, diversos músicos talentosos passaram pelo Brasil Papaya, contribuindo significativamente para sua trajetória e evolução.
                    </p>
                    <p className="details">
                        Desde 2019, a formação se mantém estável com Dudu Pimentel, Renato Pimentel, Babi e no baixo e Ginho Bernardes na bateria, reforçando a identidade musical da banda.
                    </p>
                    <p className="details">
                    Os guitarristas fundadores, Renato e Dudu, construíram uma reputação que abrange desde o flamenco e jazz até o nativismo.
                    </p>
                </div>
                <div className="right-column">
                    <p className="details">
                        A banda também realizou projetos paralelos de destaque, como o Trio Ponteio de Dudu e a produção musical de Renato no estúdio The Magic Place. A participação do Brasil Papaya em festivais catarinenses e turnês pelos SESC e SESI foi ampliada por colaborações com a Camerata Florianópolis, resultando nos espetáculos “Clássicos com Energia” e “Rock n’ Camerata”.
                    </p>
                    <p className="details">
                    A parceria alcançou seu ápice no Rock in Rio de 2015, onde a banda dividiu o palco com a Camerata e Steve Vai para uma plateia de 90 mil pessoas, marcando seu lugar na música instrumental brasileira.
                    </p>
                    <p className="details">
                        Comemorando 30 anos de estrada, o Brasil Papaya lança sua série de novidades, incluindo a gravação de um show especial com a Camerata de Santa Catarina, uma turnê por Santa Catarina, um aplicativo musical para promover o estudo instrumental e um documentário que celebra a trajetória única da banda.
                    </p>
                    <p className="details">
                    Ao longo dos anos, o Brasil Papaya tem provado que a música instrumental pode ser acessível, envolvente e apaixonante, conectando-se com públicos de todas as idades e lugares.
                    </p>
                </div>
            </div>
            </div>
        </section>
    );
};

export default BrasilPapayaSection;
