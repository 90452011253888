import React, { useState, useEffect, useRef } from "react";
import './Discografia.css';

const Discografia = () => {
    const albuns = [
        {
            imagem: "album-1.png",
            nome: "Brasil Papaya",
            plataformas: {
                spotify: "https://spotify.com/album1",
                youtube: "https://youtube.com/album1",
                shazam: "https://shazam.com/album1",
                tidal: "https://tidal.com/album1",
                deezer: "https://deezer.com/album1",
                amazon: "https://amazon.com/album1",
            }
        },
        {
            imagem: "album-2.png",
            nome: "Esperanza",
            plataformas: {
                spotify: "https://spotify.com/album2",
                youtube: "https://youtube.com/album2",
                shazam: "https://shazam.com/album2",
                tidal: "https://tidal.com/album2",
                deezer: "https://deezer.com/album2",
                amazon: "https://amazon.com/album2",
            }
        },
        {
            imagem: "album-3.png",
            nome: "Emancipation",
            plataformas: {
                spotify: "https://spotify.com/album3",
                youtube: "https://youtube.com/album3",
                shazam: "https://shazam.com/album3",
                tidal: "https://tidal.com/album3",
                deezer: "https://deezer.com/album3",
                amazon: "https://amazon.com/album3",
            }
        },
        {
            imagem: "album-4.png",
            nome: "Rock 'n Camerata",
            plataformas: {
                spotify: "https://spotify.com/album4",
                youtube: "https://youtube.com/album4",
                shazam: "https://shazam.com/album4",
                tidal: "https://tidal.com/album4",
                deezer: "https://deezer.com/album4",
                amazon: "https://amazon.com/album4",
            }
        },
        {
            imagem: "album-5.png",
            nome: "Outro Álbum",
            plataformas: {
                spotify: "https://spotify.com/album5",
                youtube: "https://youtube.com/album5",
                shazam: "https://shazam.com/album5",
                tidal: "https://tidal.com/album5",
                deezer: "https://deezer.com/album5",
                amazon: "https://amazon.com/album5",
            }
        }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentAlbum, setCurrentAlbum] = useState(null);

    const modalRef = useRef();

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    const openModal = (album) => {
        setCurrentAlbum(album);
        setModalVisible(true);
    };

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setModalVisible(false);
        }
    };

    useEffect(() => {
        if (modalVisible) {
            const timeout = setTimeout(() => {
                document.addEventListener('click', handleClickOutside);
            }, 100);

            return () => {
                clearTimeout(timeout);
                document.removeEventListener('click', handleClickOutside);
            };
        }
    }, [modalVisible]);

    return (
        <section className="discografia">
            <div className="discografia-container">
                <h2 className="discografia-title">Discografia</h2>
                <p className="discografia-description">Você pode ouvir a Brasil Papaya </p>
                <p className="discografia-description">na sua plataforma preferida.</p>

                <div className="carousel-discografia">
                    <div className="carousel-images-discografia">
                        {albuns.map((album, index) => (
                            <div
                                key={index}
                                className={`carousel-image-discografia ${index === currentIndex ? "active" : ""}`}
                                onClick={() => openModal(album)}
                            >
                                <img src={album.imagem} alt={album.nome} />
                            </div>
                        ))}
                    </div>

                    <div className="carousel-indicators-discografia">
                        {albuns.map((_, index) => (
                            <span
                                key={index}
                                className={`indicator-discografia ${index === currentIndex ? "active" : ""}`}
                                onClick={() => goToSlide(index)}
                            ></span>
                        ))}
                    </div>
                </div>

                {modalVisible && (
                    <div className="modal-discografia" ref={modalRef}>
                        <div className="platforms">
                            {Object.entries(currentAlbum?.plataformas || {}).map(([platform, url]) => (
                                <a key={platform} href={url} target="_blank" rel="noopener noreferrer" className="platform-link">
                                    <img src={`/${platform}.png`} alt={platform} className="platform-icon" />
                                </a>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Discografia;
