import React from "react";
import "./Aplicativo.css";

const Aplicativo = () => {
  const backgroundStyle = {
    backgroundImage: "url('section-app.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
  };

  return (
    <section className="app-section" style={backgroundStyle}>
      <div className="app-content">
        <button className="button-em-breve">EM BREVE</button>

        <h1 className="app-heading">
          Explore o aplicativo da <br /> <strong>Banda Brasil Papaya!</strong>
        </h1>

        <p className="app-intro">
          A Brasil Papaya está chegando com tudo pra deixar seus estudos
          musicais ainda mais incríveis! 🎶✨ Desenvolvido pela Cold Code,
          o app oficial da banda vem recheado de ferramentas pra você arrasar
          nos ensaios e na prática:
        </p>

        <ul className="app-features">
          <li>🎸 <strong>Afinador Eletrônico</strong> – mantenha seu instrumento sempre no tom.</li>
          <li>⏱️ <strong>Metrônomo</strong> – ritmo na veia, sem vacilar no tempo.</li>
          <li>🔊 <strong>Decibelímetro</strong> – cuide da sua audição enquanto toca!</li>
        </ul>

        <p className="app-highlight">
          E o melhor: <strong>TOTALMENTE GRÁTIS!</strong> 🆓
        </p>
        <p className="app-call">
          Fique ligado aqui no site e nas redes sociais pra ser o primeiro a
          baixar. 🚀 Não perde essa, hein? <br />
          #BrasilPapaya #MusicaInstrumental #AppBrasilPapaya
        </p>

        <p className="app-cta">Cadastre‑se e baixe gratuitamente:</p>

        <div className="app-download-buttons">
          <img
            src="button-appstore.png"
            alt="Baixar na App Store"
            className="app-button"
          />
          <img
            src="button-playstore.png"
            alt="Baixar no Google Play"
            className="app-button"
          />
        </div>
      </div>
    </section>
  );
};

export default Aplicativo;
