import React, { useState } from 'react';
import './News.css';

const News = () => {
    const [hover, setHover] = useState(false);

    return (
        <div className="news-section">
            <div className="news-header">
                <img src="news.png" alt="News Logo" className="news-logo" />
                <img src="logo-2.png" alt="News Logo" className="news-logo-2" />
            </div>

            <div className="all-news">
                <div className="news-large-images">
                    <div className="news-1">
                        <img src="news-1.png" alt="Brasil Papaya comemora 30 Anos de história!" className="large-image" />
                    </div>
                    <div className="news-2">
                        <img src="news-2.png" alt="Lorem ipsum dolor sit amet" className="large-image" />
                    </div>
                </div>

                <div className="news-container">
                    <div className="news-title">
                        <h2>Novidades</h2>
                        <button
                        className={`button-see-more-news ${hover ? "hovered" : ""}`}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                    >
                        <span className={`button-text ${hover ? "hidden" : ""}`}>Ver tudo</span>
                        <img
                            src="arrow-right.png"
                            alt="Seta"
                            className={`button-icon ${hover ? "hidden" : ""}`}
                        />
                    </button>
                    </div>
                    <div className="news-content">
                        <div className="news-item">
                            <img src="news-3.png" alt="Ensaios com Steve Vai" className="news-image" />
                            <div className="news-info">
                                <h4>Ensaio com Steve Vai</h4>
                                <p>Brasil Papaya 2015 - ROCK IN RIO</p>
                            </div>
                        </div>

                        <div className="news-item">
                            <img src="news-4.png" alt="Ensaios com Steve Vai" className="news-image" />
                            <div className="news-info">
                                <h4>Ensaio com Steve Vai</h4>
                                <p>Brasil Papaya 2015 - ROCK IN RIO</p>
                            </div>
                        </div>

                        <div className="news-item">
                            <img src="news-5.png" alt="Ensaios com Steve Vai" className="news-image" />
                            <div className="news-info">
                                <h4>Ensaio com Steve Vai</h4>
                                <p>Brasil Papaya 2015 - ROCK IN RIO</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;
