import React, { useState } from "react";
import "../../App.css";

import BrasilPapayaSection from "../BrasilPapaya/BrasilPapayaSection";
import Discografia from "../Discografia/Discografia";
import Galeria from "../Galeria/Galeria";
import ProximosShowsSection from "../ProximosShows/ProximosShowsSection";
import News from "../News/News";
import Aplicativo from "../Aplicativo/Aplicativo";
import Contato from "../Contato/Contato";
import Footer from "../Footer/Footer";
import MemoriasSection from "../Memorias/MemoriasSection";

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  /*  ████████  CARROSSEL  ████████ */
  const carouselData = [
    {
      image: "background-1.png",
      title: "30 ANOS DE BRASIL PAPAYA",
      subtitle: "Instrumental que pulsa rock, brasilidade e energia",
      description:
        "Desde 1993 derrubando barreiras e provando que música instrumental pode ser visceral, acessível e apaixonante.",
      buttons: [
        { label: "Fale conosco", style: "primary", href: "#contato" },
        { label: "Próximos shows", style: "secondary", href: "#agenda" }
      ]
    },
    {
      image: "background-2.png",
      title: "TOUR 2025 – CLÁSSICOS COM ENERGIA",
      subtitle: "Santa Catarina de ponta a ponta",
      description:
        "Prepare‑se para uma viagem sonora com a Camerata SC, repertório novo e a mesma pegada que incendiou o Rock in Rio!",
      buttons: [{ label: "Garanta seu ingresso", style: "primary", href: "#agenda" }]
    },
    {
      image: "background-3.png",
      title: "APP BRASIL PAPAYA",
      subtitle: "Afinador, metrônomo e muito mais no seu bolso",
      description:
        "Ferramentas gratuitas para você estudar, ensaiar e cuidar da audição – tudo no mesmo aplicativo.",
      buttons: [{ label: "Baixe o aplicativo", style: "primary", href: "#app" }]
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const nextSlide = () =>
    setCurrentIndex(prev => (prev === carouselData.length - 1 ? 0 : prev + 1));
  const prevSlide = () =>
    setCurrentIndex(prev => (prev === 0 ? carouselData.length - 1 : prev - 1));
  const goToSlide = index => setCurrentIndex(index);

  const { image, title, subtitle, description } = carouselData[currentIndex];

  return (
    <div className="App" id="home">
      <div className="container">
        {/* Header */}
        <header className="header">
          <div className="logo">
            <a href="#home" onClick={closeMenu}>
              <img src="logo.png" alt="Logo" className="logo-desktop" />
              <img src="logo-cell.png" alt="Logo Celular" className="logo-cell" />
            </a>
          </div>

          {/* Menu */}
          <nav className={`menu ${menuOpen ? "open" : ""}`}>
            <ul>
              <li>
                <a href="#home" onClick={closeMenu}>Home</a>
              </li>
              <li>
                <a href="#a-banda" onClick={closeMenu}>Banda</a>
              </li>
              <li>
                <a href="#discografia" onClick={closeMenu}>Discografia</a>
              </li>
              <li>
                <a href="#galeria" onClick={closeMenu}>Galeria</a>
              </li>
              <li>
                <a href="#agenda" onClick={closeMenu}>Agenda</a>
              </li>
              <li>
                <a href="#imprensa" onClick={closeMenu}>Imprensa</a>
              </li>
              <li>
                <a href="#contato" onClick={closeMenu}>Contato</a>
              </li>
            </ul>
          </nav>

          {/* Botões no Header */}
          <div className="header-buttons">
            <a href="#loja" className="btn loja" onClick={closeMenu}>
              <i className="fas fa-store icon" />
              Loja
            </a>
            <a href="#app" className="btn app" onClick={closeMenu}>
              Baixe nosso App
              <i className="fas fa-download icon" />
            </a>
          </div>

          {/* Menu Hambúrguer (Mobile) */}
          <div className="hamburger" onClick={toggleMenu}>
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
        </header>

        {/* Main content */}
        <main className="main-content">
          <div className="carousel">
            <div
              className="carousel-image"
              style={{ backgroundImage: `url(${image})` }}
            />

            {/* Navigation Buttons */}
            <div className="carousel-controls">
              <button className="carousel-button prev" onClick={prevSlide}>
                &#10094;
              </button>
              <button className="carousel-button next" onClick={nextSlide}>
                &#10095;
              </button>
            </div>

            {/* Indicators */}
            <div className="carousel-indicators">
              {carouselData.map((_, index) => (
                <span
                  key={index}
                  className={`indicator ${index === currentIndex ? "active" : ""}`}
                  onClick={() => goToSlide(index)}
                />
              ))}
            </div>
          </div>

          <div className="hero-text">
            <h2>{title}</h2>
            <h1>{subtitle}</h1>
            <p>{description}</p>
            <div className="buttons">
              {carouselData[currentIndex].buttons.map((button, index) => (
                <a
                  key={index}
                  href={button.href}
                  className={`btn-home ${
                    button.style === "primary" ? "fale-conosco" : "proximos-shows"
                  }`}
                  onClick={closeMenu}
                >
                  {button.label}
                  {button.style === "secondary" && <span>&#8599;</span>}
                </a>
              ))}
            </div>
          </div>
        </main>
      </div>

      {/* Seções abaixo do herói */}
      <section id="a-banda">
        <BrasilPapayaSection />
      </section>

      <section id="discografia">
        <Discografia />
      </section>

      <section id="galeria">
        <Galeria />
      </section>

      <section id="agenda">
        <ProximosShowsSection />
      </section>

      <section id="imprensa">
        <News />
      </section>

      <section id="memorias">
        <MemoriasSection />
      </section>

      {/* Documentário full‑screen */}
      <div className="documentario">
        <img src="section-8.png" alt="Documentary Section" className="full-screen-image" />
      </div>

      <section id="app">
        <Aplicativo />
      </section>

      <section id="contato">
        <Contato />
      </section>

      <Footer />
    </div>
  );
};

export default Home;
