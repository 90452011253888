import React from "react";
import "./Footer.css";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-about">
          <img
            src="logo.png"
            alt="Logo da Banda Brasil Papaya"
            className="footer-logo"
          />
          <p className="footer-description">
            Nasceu com a missão de derrubar o estigma de que música instrumental é elitista e inacessível.
          </p>
        </div>

        <div className="footer-nav">
          <h3>MENU</h3>
          <ul>
            <li><a href="/home">Home</a></li>
            <li><a href="/sobre">Sobre a Banda</a></li>
            <li><a href="/musica">Música</a></li>
            <li><a href="/galeria">Galeria</a></li>
            <li><a href="/imprensa">Imprensa</a></li>
            <li><a href="/agenda">Agenda</a></li>
          </ul>
        </div>

        <div className="footer-contact">
          <h3>ATENDIMENTO</h3>
          <ul>
            <li><a href="tel:+5548991441074">
              <img src="call.png" alt="Facebook" className="social-icon" />
              (48) 99144-1074</a></li>
            <li><a href="mailto:brasilpapaya@hotmail.com">
              <img src="mail.png" alt="Facebook" className="social-icon" />
              brasilpapaya@hotmail.com</a></li>
            <li><a href="mailto:entretenimento.g3@gmail.com">
              <img src="mail.png" alt="Facebook" className="social-icon" />
              entretenimento.g3@gmail.com</a></li>
            <li><a href="mailto:themagicplace@hotmail.com">
              <img src="mail.png" alt="Facebook" className="social-icon" />
              themagicplace@hotmail.com</a></li>
          </ul>
          <h3>REDES SOCIAIS</h3>
          <div className="footer-socials">
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <img src="instagram.png" alt="Instagram" className="social-icon" />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <img src="facebook.png" alt="Facebook" className="social-icon" />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <img src="youtube-small.png" alt="YouTube" className="social-icon" />
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="footer-sponsors">
          <div>
            <h3>REALIZAÇÃO</h3>
            <div className="footer-sponsor-logos">
              <img src="magic-place.png" alt="The Magic Place" className="sponsor-logo" />
            </div>
          </div>

          <div>
            <h3>INCENTIVADORES</h3>
            <div className="footer-sponsor-logos">
              <img src="fort.png" alt="Fort Atacadista" className="sponsor-logo" />
              <img src="tirol.png" alt="Tirol" className="sponsor-logo" />
            </div>
          </div>

          <div>
            <h3>INCENTIVO ESTADUAL</h3>
            <div className="footer-sponsor-logos">
              <img src="pic.png" alt="PIC" className="sponsor-logo" />
              <img src="sc.png" alt="Fundação Catarinense" className="sponsor-logo" />
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
      <p>
        © {year} <strong>Cold Code LTDA</strong> · Todos os direitos reservados
      </p>
      </div>
    </footer>
  );
};

export default Footer;
