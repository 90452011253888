import React from "react";
import "./Contato.css";

const Contato = () => {
    const backgroundStyle = {
        backgroundImage: "url('section-contato.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
    };

    return (
        <section className="contact-section" style={backgroundStyle}>
            <div className="contact-form-container">
                <h1 className="contact-heading">Fale Conosco</h1>
                <p className="contact-description">
                    Você pode nos enviar uma mensagem! Preencha suas informações.
                </p>
                <form className="contact-form">
                    <input
                        type="text"
                        name="name"
                        placeholder="Nome completo"
                        className="contact-input"
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="contato@empresa.com.br"
                        className="contact-input"
                    />
                    <input
                        type="text"
                        name="phone"
                        placeholder="(XX) XXXXX-XXXX"
                        className="contact-input"
                    />
                    <textarea
                        name="message"
                        placeholder="Deixe uma mensagem"
                        className="contact-textarea"
                    ></textarea>
                    <button type="submit" className="contact-submit-btn">
                        Enviar
                    </button>
                </form>
            </div>
        </section>
    );
};

export default Contato;
